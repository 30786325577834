* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --text: #01130b;
  --background: #fafffd;
  --primary: #7bf9be;
  --secondary: #a3ffd1;
  --accent: #0bcd72;
}

body {
  background-color: var(--secondary);
}

.navigation {
  display: flex;
  padding: 0.75rem 1rem;
  background-color: var(--primary);
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", 'Times New Roman', Times, serif;
  width: 100%;
}

.navigation ul {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  width: 20%;
  font-size: 1.25rem;
  list-style: none;
}

.navigation a {
  text-decoration: none;
  color: var(--text);
  font-weight: bold;
}

.navigation li {
  padding: 0.25rem;
}

.navigation li:nth-child(2) {
  border-left: 2px solid var(--text);
  border-right: 2px solid var(--text);
}

.navigation h1 {
  font-size: 2.5rem;
}
