* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --text: #01130b;
  --background: #fafffd;
  --primary: #7bf9be;
  --secondary: #a3ffd1;
  --accent: #0bcd72;
}

.Home-page {
  width: 100%;
  padding: 1.5rem;
  text-align: justify;
  margin-top: 3rem;
}

.Home-page p {
  font-family: 'Roboto', 'Times New Roman', Times, serif;
  width: 95%;
  margin-bottom: 0.5rem;
}

.Home-page h1 {
  font-family: "Oswald", 'Times New Roman', Times, serif;
  font-size: 2.25rem;
  margin-bottom: 1.75rem;
}
