@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Roboto&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --text: #01130b;
  --background: #fafffd;
  --primary: #7bf9be;
  --secondary: #a3ffd1;
  --accent: #0bcd72;
}

.calculator {
  width: 60%;
  padding: 0;
  font-family: 'Montserrat', 'Times New Roman', Times, serif;
}

.outputs {
  width: 100%;
  font-size: 1.25rem;
  color: var(--secondary);
  background-color: var(--text);
  padding: 1rem;
  text-align: end;
}

.row1 {
  display: flex;
  justify-content: space-evenly;
  gap: 0;
}

.row1 button {
  width: 33%;
  padding: 0.75rem;
  font-weight: bold;
  font-size: 1rem;
  border: 1px solid rgb(156, 123, 123);
}

.far-right {
  background-color: var(--accent);
}

.rows {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bottom {
  display: flex;
  font-size: 1rem;
  width: 100%;
  font-weight: bold;
  border: 1px solid rgb(156, 123, 123);
}

.zero {
  width: 50%;
  padding: 0.75rem;
  font-weight: bold;
}

.point {
  width: 25%;
  padding: 0.75rem;
  font-weight: bold;
}

.bottom .far-right {
  width: 25%;
  font-size: 1rem;
  border: 1px solid rgb(156, 123, 123);
  font-weight: bold;
}

.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 1rem;
  margin-top: 2rem;
}

.text {
  padding: 1rem;
  font-size: 1.25rem;
  font-family: "Roboto", 'Times New Roman', Times, serif;
  width: 40%;
}
